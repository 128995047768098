import { useEffect, useState, useRef } from "react";

export const useScrollspy = (elements, options) => {
  const [
    currentIntersectingElementIndex,
    setCurrentIntersectingElementIndex,
  ] = useState(-1);

  const rootMargin = "-40% 0px";  // set to center of screen

  const observer = useRef();

  useEffect(() => {
    
    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(
      (entries) => {
        // find the index of the section that is currently intersecting
        const indexOfElementIntersecting = entries.findIndex((entry) => {
          return entry.intersectionRatio > 0;
        });

        // set this index to the state
        setCurrentIntersectingElementIndex(indexOfElementIntersecting);
      },
      {
        threshold: (options && options.threshold) || 0,
        root: (options && options.root) || null,
        rootMargin: rootMargin
      }
    )
    
    const { current: currentObserver } = observer;
      
    // observe all the elements passed as argument of the hook
    elements.forEach((element) =>
      element ? currentObserver.observe(element) : null
    );
  
    return () => currentObserver.disconnect();
}, [elements, options, rootMargin]);

  return [currentIntersectingElementIndex];
}