import React, { useEffect, useState } from "react";
import styled from "styled-components"
import { motion } from "framer-motion"
import { Container, Row, Col } from "react-bootstrap"
import { useScrollspy } from "../hooks/useScrollspy"
import { useUIState } from "../contexts/UIStateContext"
import { useNavState } from "../contexts/NavStateContext"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import ArrowNav from "../components/ArrowNav/ArrowNav"
import About from "../svg/about.svg"
import Coop from "../svg/coop.svg"
import Lex from "../svg/lex.svg"
import Nanna from "../svg/nanna.svg"

const View = styled(Container)`
    min-height: calc(100vh - ${props => props.offset}px);     

    @media (max-width: 768px) {
        min-height: calc(100vh - ${props => props.mobileoffset}px);
      }

    p, a, span{
        font-weight: bold;
        font-size: 0.8rem;
    }
    
    svg{
        width: 250px;
    }

    #about{
        svg{
            width: 270px;
            height: 270px;
        }

        @media (min-width: 576px) {
            svg{
                 width: 350px;
                 height: 350px;
            }
        }

        @media (min-width: 768px) {
           svg{
                width: 350px;
                height: 350px;
           }
        }
    
        @media (min-width: 992px) {
           svg{
                width: 400px;
                height: 400px;
           }
        }
    
        @media (min-width: 1200px) {
            svg{
                width: 450px;
                height: 450px;
            }
        }
    }

    #lex{
        svg{
            width: 300px;
            height: 300px;
        }
    
        @media (min-width: 576px) {
            svg{
                 width: 350px;
                 height: 350px;
            }
         }

        @media (min-width: 768px) {
           svg{
                width: 400px;
                height: 400px;
           }
        }
    
        @media (min-width: 992px) {
           svg{
                width: 450px;
                height: 450px;
           }
        }
    
        @media (min-width: 1200px) {
            svg{
                width: 500px;
                height: 500px;
            }
        }
    }

    #nanna{
        svg{
            width: 300px;
            height: 300px;
        }
    
        @media (min-width: 768px) {
           svg{
                width: 300px;
                height: 300px;
           }
        }
    
        @media (min-width: 992px) {
           svg{
                width: 400px;
                height: 400px
           }
        }
    
        @media (min-width: 1200px) {
            svg{
                width: 450px;
                height: 450px;
            }
        }
    }

    #coop{
        svg{
            width: 250px;
            height: 250px;
        }
    
        @media (min-width: 768px) {
           svg{
                width: 300px;
                height: 300px;
           }
        }
    
        @media (min-width: 992px) {
           svg{
                width: 350px;
                height: 350px;
           }
        }
    
        @media (min-width: 1200px) {
            svg{
                width: 400px;
                height: 400px
            }
        }
    }

`

const Section = styled.section`
    padding: 10px 12px 50px;
    min-height: calc(${props => props.$noheight ? '0vh' : '100vh'} - ${props => props.mobileoffset}px);

    @media(min-width: 992px){
        min-height: calc(${props => props.$noheight ? '0vh' : '100vh'} - ${props => props.offset}px);
        padding: 0 12px 0;
    }


`

const Strong = styled.strong`
    color: $orange
`

const CustomAnchor = styled.a`
    text-decoration: none;

      :hover{
          text-decoration: underline;
      }
`

const Resonator = ({ location }) => {
    const { mobileoffset, totalOffset } = useUIState()
    const { setInViewport } = useNavState()
    const [elements, setElements] = useState([]);
    const hash = location.hash;
    const ids = ['about', 'lex', 'nanna', 'coop'];

    useEffect(() => {
        const htmlElements = ids.map((item) =>
          document.querySelector(`div[id="${item}"]`)
        );
        setElements(htmlElements);
    }, []);

    const buildThresholdArray = () => Array.from(Array(100).keys(), i => i / 1000);

    const [currentActiveIndex] = useScrollspy(elements, {
        offset: totalOffset,
        threshold: buildThresholdArray()
    });
    
    useEffect(() => {
        if (elements.length === 4) {
            setInViewport(`#${ids[currentActiveIndex]}`)
        }
    }, [elements.length, setInViewport, currentActiveIndex])

    return(
        <Layout location={location} activeProjectType="resonator" activeCategory={hash} backgroundColor="bgRed">
            <View 
                fluid 
                className="px-0 pb-5 overflow-hidden"
                offset={totalOffset}
                mobileoffset={mobileoffset}>
                <Seo title="Resonator"
                    description="Resonator Coop Architektur + Design in Aschaffenburg ist ein Gestaltungs- und Architekturbüro mit einem umfassenden Leistungsspektrum. Architekt Lex Rijkers und Designerin Nanna Hirsch planen und realisieren individuelle Projekte von nachhaltigem Wert."
                    type="AboutPage" />
                <Section 
                    className="pt-md-5 pt-lg-0"
                    id="about"
                    offset={totalOffset} 
                    mobileoffset={mobileoffset}
                    as={motion.div} 
                    initial={{
                        opacity: 0
                        }}
                    animate={{
                        opacity: 1
                        }}
                    exit={{
                        opacity: 0
                        }}
                    transition={{
                        duration: 0.3
                    }}>
                    <Row className="d-flex align-items-center">
                        <Col md={{ span: 6, order: 2 }} lg={{ span: 4 }} xl={{ span: 3 }} className="d-flex justify-content-center justify-content-lg-end pt-md-4 pt-lg-5">
                            <About/> 
                        </Col>
                        <Col md={{ span: 6, order: 1 }} lg={{ span: 4, offset: 3 }} xl={{ span: 5, offset: 2 }} className="text-white pt-5">
                            <p>Resonator Coop Architektur + Design wurde 2001 vom Architekten Lex Rijkers und der Designerin Nanna Hirsch gegründet.</p>
                            <p>Fachkompetenz und langjährige Berufserfahrung in unseren Kerngebieten Architektur und Design sowie die fortwährende Erneuerung durch interdisziplinäre Zusammenarbeit und Austausch bilden die Basis unserer Arbeitsweise.</p>
                            <p>Neben den klassischen Aufgabengebieten in den Bereichen Architektur und Design betreuen wir im direkten Zusammenspiel der unterschiedlichen Disziplinen auch Projekte aus soziokulturellen und künstlerischen Bereichen.</p>
                            <p>Die erfolgreiche Realisierung qualitativ hochwertiger Gestaltungslösungen stellt komplexe Anforderungen: Wir entwickeln anspruchsvolle Konzepte und setzen sie durch effektives Projektmanagement eins zu eins um.</p>
                        </Col>
                    </Row>
                </Section>
                <Section 
                    className="d-flex align-items-center"
                    id="lex"
                    offset={totalOffset}
                    mobileoffset={mobileoffset}
                    as={motion.div}
                    initial={{
                        opacity: 0
                    }}
                    animate={{
                        opacity: 1
                    }}
                    exit={{
                        opacity: 0
                    }}
                    transition={{
                        duration: 0.3
                    }}>
                    <Row className="d-flex align-items-center">
                        <Col md={{ span: 6, order: 1 }} lg={{ span: 4, offset: 3 }} xl={{ span: 3, offset: 2 }} className="d-flex justify-content-center justify-content-lg-start">
                            <Lex/>
                        </Col>
                        <Col md={{ span: 6, order: 2 }} lg={{ span: 4 }} xl={{ span: 5 }} className="text-white pt-5 pt-md-0">
                            <p><Strong>1966</Strong> geboren in Schijndel, NL <Strong>1986-93</Strong> Studium Maschinenbau und Architektur an der TU Braunschweig und der TH Darmstadt</p>
                            <p>Architekt <Strong>1989-92</Strong> für Novotny + Mähner, Offenbach <Strong>1993-94</Strong> für Piske + Köhler, Frankfurt <Strong>1994-98</Strong> für Braun, Schlockermann + Köhler, Frankfurt <Strong>1998-2001</Strong> Zusammenarbeit mit Bub.Sauerwein.Architekten, Frankfurt <Strong>seit 2001</Strong> Resonator Coop Architektur + Design <Strong>2003-08</Strong> Zusammenarbeit mit Daniel Milohnic, Frankfurt <Strong>seit 2015</Strong> Zusammenarbeit mit Lautenschläger Architekt, Aschaffenburg</p>
                        </Col>
                    </Row>
                </Section>
                <Section
                    className="d-flex align-items-center"
                    id="nanna"
                    offset={totalOffset}
                    mobileoffset={mobileoffset}
                    as={motion.div}
                    initial={{
                        opacity: 0
                    }}
                    animate={{
                        opacity: 1
                    }}
                    exit={{
                        opacity: 0
                    }}
                    transition={{
                        duration: 0.3
                    }}>
                    <Row className="d-flex align-items-center">
                        <Col md={{ span: 6, order: 2 }} lg={{ span: 4 }} xl={{ span: 3 }} className="d-flex justify-content-center justify-content-lg-end">
                            <Nanna/>
                        </Col>
                        <Col md={{ span: 6, order: 1 }} lg={{ span: 4, offset: 3 }} xl={{ span: 5, offset: 2 }} className="text-white pt-5 pt-md-0">
                            <p><Strong>1967</Strong> geboren in Aschaffenburg <Strong>1987-92</Strong> Studium Kommunikationsdesign an der FH Darmstadt <Strong>1992-95</Strong> Studium Anglistik, Germanistik, Italianistik an der Universität Konstanz</p>
                            <p>Kommunikationsdesignerin / Texterin <Strong>1991</Strong> für Scott Sederstrom Graphics, Columbus USA <Strong>1992-95</Strong> für Art Connection Systems, Konstanz <Strong>1995-1996</Strong> für Quandel Design, Frankfurt <Strong>seit 1996</Strong> Zusammenarbeit mit Gestaltungsbüro 29. April, Darmstadt <Strong>seit 2001</Strong> Resonator Coop Architektur + Design</p>
                        </Col>
                    </Row>
                </Section>
                <Section 
                    className="d-flex align-items-center justify-content-center px-0"
                    id="coop"
                    offset={totalOffset}
                    mobileoffset={mobileoffset}
                    as={motion.div}
                    initial={{
                        opacity: 0
                    }}
                    animate={{
                        opacity: 1
                    }}
                    exit={{
                        opacity: 0
                    }}
                    transition={{
                        duration: 0.3
                    }}>
                    <Row className="d-flex align-items-center w-100">
                        <Col md={{ span: 6, order: 1 }} lg={{ span: 4, offset: 3 }} xl={{ span: 3, offset: 2 }} className="d-flex justify-content-center justify-content-lg-start">
                            <Coop />
                        </Col>
                        <Col md={{ span: 6, order: 2 }} lg={{ span: 4 }} xl={{ span: 5 }} className="text-white pt-5 pt-md-0">
                            <p>Je nach Größe und Anforderungen des Projekts arbeiten wir in Teams von unterschiedlicher Zusammensetzung.</p>
                            <p>Hierbei können wir auf unsere festen Mitarbeiter sowie ein erweitertes Netzwerk aus Partnerbüros, freien Mitarbeitern und externen Fachkräften bauen.</p>
                            <p>
                                <CustomAnchor href="/pdf/resonator/auftraggeber.pdf" className="text-orange" target="_blank" rel="noopener noreferrer">
                                    Auftraggeber, Wettbewerbe + Auszeichnungen
                                </CustomAnchor>
                            </p>
                            <p>
                                <CustomAnchor href="/pdf/resonator/publikationen.pdf" className="text-orange" target="_blank" rel="noopener noreferrer">
                                    Publikationen + Ausstellungsteilnahmen
                                </CustomAnchor>
                            </p>
                        </Col>
                    </Row>
                </Section>
                <Section
                    className="d-flex align-items-center justify-content-center px-0"
                    $noheight
                    offset={totalOffset}
                    mobileoffset={mobileoffset}
                    as={motion.div}
                    initial={{
                        opacity: 0
                    }}
                    animate={{
                        opacity: 1
                    }}
                    exit={{
                        opacity: 0
                    }}
                    transition={{
                        duration: 0.3
                    }}>
                    <ArrowNav location={location} bgColor="bgRed" white="true" resonator />
                </Section>
            </View>
        </Layout>
    )
}

export default Resonator